// TODO: fix eslint errors
/* eslint-disable */
import { token } from '@atlaskit/tokens'
import { Inline, Box, xcss } from '@atlaskit/primitives'
import { TDirectManager } from './AIStatusButton'
import styled from 'styled-components'
import { FC, ReactNode } from 'react'
import ManagerLinkButton from './ManagerLinkButton'

const LinkElm: FC<HTMLAnchorElement | { children: ReactNode }> = styled.a`
  margin-top: ${token('space.200')};
  color: ${token('color.link')};
  display: 'flex';
  align-items: 'start';
  font-size: '14px';
  font-style: 'normal';
  font-weight: 500;
  line-height: '20px';
`

export default function ModalContentNoAIButton({ directManager }: { directManager: TDirectManager }) {
  return (
    <>
      <Box
        xcss={xcss({
          color: 'color.text.subtle',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '20px',
          width: '348px',
        })}
      >
        {directManager.id
          ? 'Your analysis status will appear here as soon as it is assigned by the manager.'
          : 'Your analysis status will appear here as soon as it is assigned by the HR manager.'}
      </Box>
      <Box>{directManager.id && <ManagerLinkButton directManager={directManager} />}</Box>
    </>
  )
}
