import moment from 'moment'
import { WorkLocationProps, WorkLocationResponseProps } from './workLocation.types'
import { LOCATIONS } from '../../../../constants'

const getGMTOffsetByTimezoneName = (timezone: string): string => {
  //@ts-ignore
  const offsetMinutes = moment.tz(timezone).utcOffset()
  const offsetSign = offsetMinutes < 0 ? '-' : '+'
  const absoluteOffsetMinutes = Math.abs(offsetMinutes)
  const hours = Math.floor(absoluteOffsetMinutes / 60)
    .toString()
    .padStart(2, '0')
  const minutes = (absoluteOffsetMinutes % 60).toString().padStart(2, '0')

  return `(GMT${offsetSign}${hours}:${minutes})`
}

const transformToADSSelectOptions = (options: Array<WorkLocationResponseProps>): Array<WorkLocationProps> =>
  options.map((option) => ({
    ...option,
    dataValue: option.value,
    label: `${option.value.countryCode}, ${option.value.timezone} ${getGMTOffsetByTimezoneName(option.value.timezone)}`,
    value: option.id,
  }))

export const fetchWorkLocationAPI = async () => {
  try {
    const response = await fetch(LOCATIONS, {
      method: 'GET',
      headers: { Accept: 'application/json' },
    })

    const data = (await response.json()) as Array<WorkLocationResponseProps>

    return transformToADSSelectOptions(data)
  } catch (error) {
    console.error(error)
    throw new Error()
  }
}
