import { AnnouncementGetListOrderByEnum, OrderEnum } from 'utils/constants/announcements'
import {
  AnnouncementsFiltersType,
  AnnouncementsQueryType,
  AnnouncementsTableType,
} from 'interfaces/common/announcements.interface'
import { IAnnouncementsModalProps } from './types'
import { TableFiltersType } from './components'

export const defaultAnnouncementsDate = { items: [], total: 0 }

export enum AnnouncementModalTypeEnum {
  CREATE = 'create',
  EDIT = 'edit',
  DELETE = 'delete',
}

export const defaultQuery: AnnouncementsQueryType = {
  size: 5,
  page: 1,
  sort: AnnouncementGetListOrderByEnum.StartDate,
  order: OrderEnum.DESC,
  startDate: '',
  endDate: '',
  priority: [],
  search: '',
  locations: [],
}

export const announcementModalDefaultState: IAnnouncementsModalProps = {
  isOpen: false,
  type: AnnouncementModalTypeEnum.CREATE,
  payload: null,
}

export const defaultTableState: AnnouncementsTableType = {
  size: 5,
  page: 1,
  sort: AnnouncementGetListOrderByEnum.StartDate,
  order: OrderEnum.DESC,
}

export const defaultTableFilters: AnnouncementsFiltersType = {
  startDate: '',
  endDate: '',
  priority: [],
  search: '',
  locations: [],
}

export const defaultTableFilterValues: TableFiltersType = {
  search: '',
  priority: [],
  locations: [],
}
