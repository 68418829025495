const BASE_URL = process.env.REACT_APP_API_BASE_URL

export const AUTH: string = `${BASE_URL}/auth`
export const GOOGLE_AUTH: string = `${BASE_URL}/auth/google`
export const RANDOM_PHRASES: string = `${BASE_URL}/random-phrases`
export const LINKS: string = `${BASE_URL}/links`
export const GENDERS: string = `${BASE_URL}/genders`
export const LEVELS: string = `${BASE_URL}/levels`
export const STATUSES: string = `${BASE_URL}/statuses`
export const STATUSES_TRANSITIONS: string = `${BASE_URL}/statuses-transitions`
export const LOCATIONS: string = `${BASE_URL}/locations`
export const ORG_CHART: string = `${BASE_URL}/orgchart`
export const COUNTRIES: string = `${BASE_URL}/countries`
export const RANDOM_PHRASES_WIDGET: string = `${BASE_URL}/random-phrases/widget`
export const LINKS_WIDGET: string = `${BASE_URL}/links/widget`
export const JOB_TITLES: string = `${BASE_URL}/job-titles`
export const JOB_TITLES_CATEGORIES: string = `${BASE_URL}/job-title-categories`
export const PUSH_NOTIFICATIONS: string = `${BASE_URL}/push-notifications`
export const PROFESSIONAL_HOLIDAYS: string = `${BASE_URL}/professional-holidays`
export const CORPORATE_EVENTS: string = `${BASE_URL}/corporate-events`
export const STATE_HOLIDAYS: string = `${BASE_URL}/state-holidays`
export const EMPLOYEES: string = `${BASE_URL}/employees`
export const PAYROLL: string = `${BASE_URL}/payroll`
export const EMPLOYEE: string = `${BASE_URL}/employee`
export const TIME_OFF_POLICIES: string = `${BASE_URL}/time-off-policies`
export const CALENDAR: string = `${BASE_URL}/calendar`
export const HOLIDAYS: string = `${BASE_URL}/holidays`
export const SEARCH: string = `${BASE_URL}/employee-search`
export const ACCRUED_DAYS_BALANCES: string = `${BASE_URL}/accrued-days-balances`
export const CALENDAR_WIDGET: string = `${BASE_URL}/holidays/widget`
export const ACCESS_LEVELS: string = `${BASE_URL}/access-levels`
export const EMPLOYEE_SETTINGS: string = `${BASE_URL}/employee-settings`
export const FILTERS: string = `${BASE_URL}/filters`
export const INTERVIEWS: string = `${BASE_URL}/interviews`
export const ANNOUNCEMENTS: string = `${BASE_URL}/announcements`
