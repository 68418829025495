// TODO: fix eslint errors
/* eslint-disable */
import React, { FC, useState, useCallback } from 'react'
import { Box, Inline, Stack } from '@atlaskit/primitives'
import Button from '@atlaskit/button/new'
import moment from 'moment'
import { DayValue } from '@hassanmojab/react-modern-calendar-datepicker'
import { FilterCalendar, defaultRangeAttendanceLog, DayRange } from 'components/UI/FilterCalendar'
import { TypeIconsCollection } from 'shared/TimeOffPolicy/constants/NewTypeIconsCollection'
import { StyledTable, StyledTabsWrap, StyledTitle } from './TimeOffLogDrawerStyles'
import PreferencesIcon from '@atlaskit/icon/glyph/preferences'
import DynamicTable from '@atlaskit/dynamic-table'
import { tableHead, tableRows } from './sample-data/sample-data'
import { EmptyDataPlaceholder } from 'components/UI/EmptyDataPlaceholder/EmptyDataPlaceholder'
import { EmptyFiltersPlaceholder } from 'components/UI/EmptyFiltersPlaceholder/EmptyFiltersPlaceholder'
import { TimeOffLogModal } from './TimeOffLogModal/TimeOffLogModal'

export default function TimeOffLogDrawer() {
  const getDateRangeFromParams = (params: URLSearchParams): DayRange => {
    const startDateParam = params.get('startDate')
    const endDateParam = params.get('endDate')

    if (startDateParam && endDateParam) {
      const startDate = moment.utc(startDateParam, 'YYYY-MM-DD').toDate()
      const startDay: DayValue = {
        day: startDate.getUTCDate(),
        month: startDate.getUTCMonth() + 1,
        year: startDate.getUTCFullYear(),
      }

      const endDate = moment.utc(endDateParam, 'YYYY-MM-DD').toDate()
      const endDay: DayValue = {
        day: endDate.getUTCDate(),
        month: endDate.getUTCMonth() + 1,
        year: endDate.getUTCFullYear(),
      }

      return { from: startDay, to: endDay }
    }
    return defaultRangeAttendanceLog.range
  }
  const params = new URLSearchParams(window.location.search)
  const initialDateRange = getDateRangeFromParams(params)
  const [dateRange, setDateRange] = useState<DayRange>(initialDateRange)

  const [dataEmpty, setDataEmpty] = useState(false)
  const [filtersEmpty, setFiltersEmpty] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const openModal = useCallback(() => setIsModalOpen(true), [])
  const closeModal = useCallback(() => setIsModalOpen(false), [])

  return (
    <>
      <Box paddingInlineStart="space.300" paddingInlineEnd="space.800" paddingBlockEnd="space.800">
        <Stack space="space.300">
          <Inline>
            <StyledTitle>Time off & special statuses log</StyledTitle>
          </Inline>
          <Stack space="space.200">
            <Inline alignBlock="center" spread="space-between">
              <FilterCalendar
                setDateRange={setDateRange}
                defaultRange={{
                  range: dateRange,
                  name: defaultRangeAttendanceLog.name,
                }}
              />

              <Button iconBefore={PreferencesIcon} appearance="primary" onClick={openModal}>
                Edit balance
              </Button>
            </Inline>
            <Inline>
              <StyledTabsWrap>
                <Button isSelected>
                  {TypeIconsCollection['vacation' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  Vacations
                </Button>

                <Button>
                  {TypeIconsCollection['vacation' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  (Auto-approve) NG General Vacation
                </Button>

                <Button>
                  {TypeIconsCollection['sickDay' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  General Sick leave
                </Button>

                <Button>
                  {TypeIconsCollection['dayOff' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  Day off
                </Button>

                <Button>
                  {TypeIconsCollection['dayOff' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  Paid day off
                </Button>

                <Button>
                  {TypeIconsCollection['birthday' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  Birthday paid day off
                </Button>

                <Button>
                  {TypeIconsCollection['remoteWork' as keyof typeof TypeIconsCollection]('small-icon', 'withouthover')}
                  Home office
                </Button>
              </StyledTabsWrap>
            </Inline>
          </Stack>
          {!dataEmpty ? (
            !filtersEmpty ? (
              <StyledTable>
                <DynamicTable
                  head={tableHead}
                  rows={tableRows}
                  rowsPerPage={12}
                  defaultPage={1}
                  loadingSpinnerSize="large"
                />
              </StyledTable>
            ) : (
              <EmptyFiltersPlaceholder
                title="No results found"
                info="Sorry, that filter combination has no results. Please try different criteria."
              />
            )
          ) : (
            <EmptyDataPlaceholder title="There is no information here yet" />
          )}
          {isModalOpen && <TimeOffLogModal isModalOpen={isModalOpen} closeModal={closeModal} />}
        </Stack>
      </Box>
    </>
  )
}
