import { Helmet } from 'react-helmet';

type HeadProps = {
    title: string;
    description: string;
    image?: string;
    url?: string;
    siteName?: string;
    metaNameTitle?: string;
}

export const Head = ({ title, description, image, url = '', siteName = "Netpeak CoreHR — HRM system" , metaNameTitle = ''}: HeadProps) => {
    const baseURL = `${window.location.protocol}//${window.location.host}`;

    const defaultImage = `${baseURL}/logo_core.svg`;

    return (
        <Helmet>
            <meta property="og:site_name" content={siteName} />
            <title>{title}</title>
            <meta name="title" content={metaNameTitle} />
            <meta name="description" content={description} />
            <meta property="og:title" content={title} />
            <meta property="og:image" content={image || defaultImage} />
            <meta property="og:url" content={baseURL + url} />
            <meta property="og:description" content={description} />
        </Helmet>
    );
}

export default Head;
