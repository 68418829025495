// TODO: Create a skeleton table component for all tales in the application

import { Box, xcss } from "@atlaskit/primitives";
import { SpanBlock } from 'components/UI/skeleton-table';
import { TableRow } from './components'
import { SkeletonTableStyles } from './styles'

export const SkeletonTable = () => (
  <SkeletonTableStyles>
    <Box
      xcss={xcss({
        display: "flex",
        gap: "space.200",
        flexDirection: "column",
      })}
    >
      <SpanBlock width="162px" height="28px" />
      <Box 
        xcss={xcss({
          display: "flex",
          justifyContent: "space-between",
        })}
      >
        <Box
          xcss={xcss({
            display: "flex",
            gap: "space.200",
          })}
        >
          {new Array(4).fill('').map((_: unknown, index) => (
            <SpanBlock key={index} width={!index ? "240px" : '160px'} height="32px" />
          ))}
        </Box>
        <SpanBlock width="125px" height="32px" />
      </Box>
    </Box>

    <Box
      xcss={xcss({
        marginTop: "space.400",
      })}
    >
      <Box
        xcss={xcss({
          display: "grid",
          gridTemplateColumns: "140px 80px 572px 208px 64px",
          alignItems: "center",
          borderBottom: "2px solid",
          borderColor: "color.border",
          height: "30px",
          paddingBottom: "space.050",
          paddingTop: "space.050",
        })}
      >
        {new Array(5).fill('').map((_: unknown, index) => (
          <SpanBlock key={index} width="48px" height="16px" marginLeft="space.100"/>
        ))}
      </Box>
      <Box
        xcss={xcss({
          display: "flex",
          flexDirection: "column",
          gap: "space.300",
          paddingTop: "space.200",
          paddingBottom: "space.200",
          borderBottom: "2px solid",
          borderColor: "color.border",
        })}
      >
        {new Array(3).fill('').map((_: unknown, index) => <TableRow key={index} />)}
      </Box>
    </Box>
  </SkeletonTableStyles>
)   
