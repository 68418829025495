import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  AnnouncementPayloadType,
  AnnouncementsDataType,
  AnnouncementsQueryType,
} from 'interfaces/common/announcements.interface'
import { BASE_URL } from './constants'

export const announcementAPI = createApi({
  reducerPath: 'announcements-api',
  baseQuery: fetchBaseQuery({ baseUrl: `${BASE_URL}/announcements` }),
  tagTypes: ['announcements'],
  endpoints: (build) => ({
    getAnnouncements: build.query<AnnouncementsDataType, AnnouncementsQueryType>({
      query: (params: AnnouncementsQueryType) => ({
        url: '',
        params,
      }),
      providesTags: [{ type: 'announcements', id: 'LIST' }],
    }),
    createAnnouncement: build.mutation<string, AnnouncementPayloadType>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'announcements', id: 'LIST' }]
        }
        return []
      },
    }),
    updateAnnouncement: build.mutation<void, { id: string; body: AnnouncementPayloadType }>({
      query: ({ id, body }) => ({
        url: `/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'announcements', id: 'LIST' }]
        }
        return []
      },
    }),
    deleteAnnouncement: build.mutation<void, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_result, error) => {
        if (!error) {
          return [{ type: 'announcements', id: 'LIST' }]
        }
        return []
      },
    }),
  }),
})

export const {
  useGetAnnouncementsQuery,
  useCreateAnnouncementMutation,
  useUpdateAnnouncementMutation,
  useDeleteAnnouncementMutation,
} = announcementAPI
