import { useMemo } from 'react'
import DynamicTable from '@atlaskit/dynamic-table'
import Pagination from '@atlaskit/pagination'
import { RowType, SortOrderType } from '@atlaskit/dynamic-table/dist/types/types'
import { AnnouncementGetListOrderByEnum, OrderEnum } from 'utils/constants/announcements'
import { getPagesCount } from 'utils/helpers/get-pages-count'
import { tableHead } from './table-head'
import { getTableRows } from './table-rows'
import { IAnnouncementsTableProps } from './types'
import { AnnouncementsTableStyles } from './styles'

export const AnnouncementsTable = (props: IAnnouncementsTableProps) => {
  const { sourceData, onEdit, onDelete, onUpdateTableState, total, loading, tableState, showPagination } = props
  const { page, size, sort, order } = tableState

  const onSetTablePage = (pageNumber: number) => {
    onUpdateTableState({ ...tableState, page: pageNumber })
  }

  const onSetTableSort = (key: AnnouncementGetListOrderByEnum, sortOrder: SortOrderType) => {
    onUpdateTableState({ ...tableState, sort: key, order: OrderEnum[sortOrder] })
  }

  const pages: Array<number> = useMemo(() => getPagesCount(total, size), [total, size])

  const tableRows: Array<RowType> = useMemo(
    () =>
      getTableRows({
        sourceData,
        onEdit,
        onDelete,
      }),
    [sourceData, onEdit, onDelete],
  )

  return (
    <AnnouncementsTableStyles>
      <DynamicTable
        head={tableHead}
        rows={tableRows}
        sortKey={sort}
        sortOrder={order}
        loadingSpinnerSize="large"
        isLoading={loading}
        onSort={({ key, sortOrder }: { key: AnnouncementGetListOrderByEnum; sortOrder: SortOrderType }) =>
          onSetTableSort(key, sortOrder)
        }
      />
      {showPagination && (
        <Pagination
          nextLabel="Next"
          label="Page"
          pageLabel="Page"
          selectedIndex={page - 1}
          pages={pages}
          onChange={(_e, pageNumber: number) => onSetTablePage(pageNumber)}
          previousLabel="Previous"
        />
      )}
    </AnnouncementsTableStyles>
  )
}
