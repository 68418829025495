import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const EmptyTableStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  max-width: 1170px;

  .title {
    color: ${token("color.text")};
    font-size: 29px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    margin: 0 0 ${token("space.200")};
  }

  .description {
    color: ${token("color.text.subtle")};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    white-space: pre-wrap;
    margin: 0
  }

  // TODO: Remove this style after create common button component with new type
  
  button {
    display: flex;
    align-items: center;
    border-radius: 3px;
    border: 1px solid ${token("color.border")};
    background: ${token("color.background.accent.blue.subtlest")};
    min-width: 138px;

    span {
      color: ${token("color.text")};

      span {
        color: ${token("color.icon")};
      }
    }

    &:hover {
      background: ${token("color.background.accent.blue.subtlest.hovered")};
    }
  }
`
