import { useMemo } from 'react'
import MultiSelectCustom from 'components/UI/MultiSelectCustom/MultiSelectCustom'
import { type ValueType as Value } from '@atlaskit/select'
import Textfield from '@atlaskit/textfield'
import { useGetLocationsQuery } from 'services/api'
import { useDebounce, DebouncedCallbackType } from 'hooks'
import { announcementPrioritySelectOptions } from '../constants'
import { IPriorityOption, Option } from '../types'
import { SearchIcon } from './components'
import { TableFiltersStyles } from './styles'
import { ITableFiltersProps } from './types'

export const TableFilters = (props: ITableFiltersProps) => {
  const { tableFilters, onUpdateTableFilters } = props
  const { data: locationsList, isLoading: isLoadingLocations } = useGetLocationsQuery()

  const onSearch: DebouncedCallbackType<string> = useDebounce<string>((value: string) => {
    onUpdateTableFilters({ ...tableFilters, search: value })
  })

  const onChangePriority = (priority: Array<IPriorityOption>) => {
    onUpdateTableFilters({ ...tableFilters, priority })
  }

  const onChangeLocation = (locations: Array<Option>) => {
    onUpdateTableFilters({ ...tableFilters, locations })
  }

  const locationSelectOptions: Value<Option[]> = useMemo(() => {
    if (!locationsList) return []

    return locationsList.map(({ name: label, id: value }) => ({
      label,
      value,
    }))
  }, [locationsList])

  return (
    <TableFiltersStyles>
      <div className="filter-body">
        <div className="text-field">
          <Textfield
            className="field"
            isCompact
            placeholder="Search"
            elemBeforeInput={<SearchIcon />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSearch(e.target.value)
            }}
          />
        </div>
        <MultiSelectCustom
          selectWidth={160}
          hasSelectAll={false}
          options={announcementPrioritySelectOptions}
          onChange={onChangePriority}
          value={tableFilters.priority}
          placeholder="Priority"
          labelledBy="Priority select"
          hideSearch
        />
        <MultiSelectCustom
          selectWidth={160}
          hasSelectAll={false}
          options={locationSelectOptions}
          onChange={onChangeLocation}
          value={tableFilters.locations}
          placeholder="Location"
          labelledBy="Location select"
          isLoading={isLoadingLocations}
        />
      </div>
    </TableFiltersStyles>
  )
}
