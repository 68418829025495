import moment from "moment";

export function getGMTOffsetByTimezoneName(timezone: string): string {
    const offsetMinutes = moment.tz(timezone).utcOffset();
    const offsetSign = offsetMinutes < 0 ? "-" : "+";
    const absoluteOffsetMinutes = Math.abs(offsetMinutes);
    const hours = Math.floor(absoluteOffsetMinutes / 60).toString().padStart(2, '0');
    const minutes = (absoluteOffsetMinutes % 60).toString().padStart(2, '0');

    return `(GMT${offsetSign}${hours}:${minutes})`;
}
