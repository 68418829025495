// TODO: fix eslint errors
/* eslint-disable */
import { FC, useEffect } from 'react'
import { NavProps } from '../Layout.types'
import { Section, SideNavigation } from '@atlaskit/side-navigation'
import PeopleIcon from '@atlaskit/icon/glyph/people'
import { NavLinkItem } from './NavLinkItem'
import { ReactComponent as LogoFull } from '../../../assets/images/logo/logo_core.svg'
import HomeIcon from '@atlaskit/icon/glyph/home'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import QueuesIcon from '@atlaskit/icon/glyph/queues'
import DepartmentIcon from '@atlaskit/icon/glyph/department'
import SettingsIcon from '@atlaskit/icon/glyph/settings'
import StopwatchIcon from '@atlaskit/icon/glyph/stopwatch'
import { ReactComponent as Stars } from '../../../assets/images/ProfileIcons/Stars.svg'
import { useEmployee } from '../../../contexts/EmployeeContext'
import Badge from '@atlaskit/badge'
import { MAIN, CALENDAR, ADMIN, REQUESTS, ATTENDANCE_LOG, SUBORDINATES_ANALYSIS, AI_ANALYSIS } from 'navigation'
import { User } from './User'
import styled from 'styled-components'
import { useSubordinates } from '../../../API/profile/subordinates'
import { ROLE_EMPLOYEE, ROLE_HR } from '../../../HOC/AccessController/AccessController.constants'
import { StyledSideBarAdmin, StyledSidebar, StyledSideBarIconBadge, StyledSideBarLogo } from './SideBarStyles'

const IconWrapper = styled.div`
  margin-top: 2px;
  svg {
    fill: currentColor;
  }
  svg path {
    fill: currentColor;
  }
`

export const SideBar: FC<NavProps> = ({ navIsOpen }) => {
  const { employee, requestCounter } = useEmployee()

  const { subordinates, fetchSubordinates } = useSubordinates(employee?.id as string)

  useEffect(() => {
    fetchSubordinates()
  }, [])

  let roleHR = null
  let roleEmployeeOnly = null
  if (employee) {
    roleHR = employee.roles.includes(ROLE_HR)
    roleEmployeeOnly = employee.roles.length === 1 && employee.roles[0] === ROLE_EMPLOYEE
  }

  return (
    <StyledSidebar $navIsOpen={navIsOpen}>
      <StyledSideBarLogo>
        <LogoFull />
      </StyledSideBarLogo>
      <SideNavigation label="CoreHR">
        <Section>
          <NavLinkItem to={MAIN} iconBefore={<HomeIcon label="Home" />}>
            Home
          </NavLinkItem>
          <NavLinkItem to={CALENDAR} iconBefore={<CalendarIcon label="Calendar" />}>
            Calendar
          </NavLinkItem>
          {subordinates?.length === 0 && !roleHR && (
            <NavLinkItem
              to={AI_ANALYSIS}
              iconBefore={
                <IconWrapper>
                  <Stars />
                </IconWrapper>
              }
            >
              AI personality analysis
            </NavLinkItem>
          )}
          <NavLinkItem
            to={REQUESTS}
            iconBefore={
              <StyledSideBarIconBadge>
                {requestCounter > 0 && (
                  <Badge appearance="primary" testId="badge">
                    {requestCounter}
                  </Badge>
                )}
                <QueuesIcon label="Requests" size="medium" />
              </StyledSideBarIconBadge>
            }
          >
            Requests
          </NavLinkItem>
          <NavLinkItem to="" isDisabled iconBefore={<DepartmentIcon label="Org Chart" />}>
            Org Chart
          </NavLinkItem>
          <NavLinkItem to={ATTENDANCE_LOG} iconBefore={<StopwatchIcon label="Attendance log" />}>
            Attendance log
          </NavLinkItem>
        </Section>
        {subordinates ? (
          subordinates?.length > 0 || roleHR ? (
            <Section title="AI PERSONALITY ANALYSIS">
              <NavLinkItem
                to={SUBORDINATES_ANALYSIS}
                iconBefore={
                  <IconWrapper>
                    <Stars />
                  </IconWrapper>
                }
              >
                Subordinates Analysis
              </NavLinkItem>
              <NavLinkItem to={AI_ANALYSIS} iconBefore={<PeopleIcon label="icons" />}>
                Colleague Analysis
              </NavLinkItem>
            </Section>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {!roleEmployeeOnly ? (
          <StyledSideBarAdmin>
            <NavLinkItem to={ADMIN} iconBefore={<SettingsIcon label="Admin Panel" />}>
              Admin Panel
            </NavLinkItem>
          </StyledSideBarAdmin>
        ) : (
          <></>
        )}

        <User />
      </SideNavigation>
    </StyledSidebar>
  )
}
