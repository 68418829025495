// TODO: fix eslint errors
/* eslint-disable */
import React, { useState, useEffect, useRef, FC } from 'react'
import './ProfessionalHolidays.scss'
import CreateProfessionalHoliday from './Modal/CreateProfessionalHoliday'
import EditProfessionalHoliday from './Modal/EditProfessionalHoliday'
import moment from 'moment'
import { ORG_CHART, PROFESSIONAL_HOLIDAYS } from '../../../../../utils/constants/api'
import DeleteProfessionalHoliday from './Modal/DeleteProfessionalHoliday'
import Button, { Size, Type } from 'components/old-ui/Button/Button'
import { ProfessionalHolidayFromApi } from '../../../../../shared/Holiday/ProfessionalHoliday/data/ProfessionalHolidayFromApi'
import { Popover } from '@headlessui/react'
import { OrgUnitFromApi } from '../../../../../shared/OrgChart/data/OrgUnitFromApi'
import {
  ROLE_ADMIN,
  ROLE_CONTENT_MANAGER,
  ROLE_OPERATIONAL_MANAGER,
} from '../../../../../shared/Security/constants/AccessLevels'
import { assertEmployee, useEmployee } from '../../../../../contexts/EmployeeContext'

interface ProfessionalHolidaysProps {
  year: string
}

export const ProfessionalHolidays: FC<ProfessionalHolidaysProps> = (props) => {
  const { employee } = useEmployee()
  assertEmployee(employee)

  const [createProfessionalHolidayModal, setCreateProfessionalHolidayModal] = useState<boolean>(false)
  const [editProfessionalHolidayModal, setEditProfessionalHolidayModal] = useState<boolean>(false)
  const [deleteProfessionalHolidayModal, setDeleteProfessionalHolidayModal] = useState<boolean>(false)

  const [professionalHolidays, setProfessionalHolidays] = useState<ProfessionalHolidayFromApi[]>([])
  const [professionalHolidayToChange, setProfessionalHolidayToChange] = useState<ProfessionalHolidayFromApi | null>(
    null,
  )
  const [professionalHolidayToDelete, deleteProfessionalHoliday] = useState<ProfessionalHolidayFromApi | null>(null)

  const [orgUnits, setOrgUnits] = useState<OrgUnitFromApi[]>([])

  function getOrgUnits() {
    fetch(`${ORG_CHART}?without-hierarchy=true`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((orgUnits: OrgUnitFromApi[]) => setOrgUnits(orgUnits))
  }

  useEffect(() => {
    getOrgUnits()
    if (props.year) {
      getProfessionalHolidays(props.year)
    }
  }, [props.year])

  function getProfessionalHolidays(year: string) {
    return fetch(`${PROFESSIONAL_HOLIDAYS}?year=${year}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((professionalHolidays: ProfessionalHolidayFromApi[]) => setProfessionalHolidays(professionalHolidays))
  }

  function showProfessionalHoliday({ professionalHoliday }: { professionalHoliday: any }): void {
    fetch(`${PROFESSIONAL_HOLIDAYS}/${professionalHoliday.id}`, {
      method: 'PUT',
      body: JSON.stringify({
        name: professionalHoliday.value.name,
        orgUnits: professionalHoliday.value.orgUnits,
        movingType: professionalHoliday.value.movingType,
        date: professionalHoliday.value.date,
        repeatAnnually: professionalHoliday.value.repeatAnnually,
        text: professionalHoliday.value.text,
        visible: !professionalHoliday.value.visible,
      }),
    }).then((response) => {
      if (response.ok) {
        getProfessionalHolidays(props.year)
      } else {
        response.json().then((jsonData) => alert(jsonData.error))
      }
    })
  }

  function editProfessionalHolidayModalVisibility(professionalHoliday: ProfessionalHolidayFromApi): void {
    setProfessionalHolidayToChange(professionalHoliday)
    document.getElementById('popover-btn')?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
    setEditProfessionalHolidayModal(true)
  }

  function confirmDeleteProfessionalHolidayModalVisibility(professionalHoliday: ProfessionalHolidayFromApi): void {
    deleteProfessionalHoliday(professionalHoliday)
    document.getElementById('popover-btn')?.dispatchEvent(new MouseEvent('click', { bubbles: true }))
    setDeleteProfessionalHolidayModal(true)
  }

  // Access levels
  const roles = [ROLE_ADMIN, ROLE_CONTENT_MANAGER, ROLE_OPERATIONAL_MANAGER] as typeof employee.roles
  const hasRole = roles.some((role) => employee.roles.includes(role))

  return (
    <div className="professional-holidays">
      {hasRole ? (
        <div className="add-button-block">
          {props.year ? (
            <Button
              text="<i className='material-icons add-item'>add</i> Add professional holiday"
              size={Size.Medium}
              type={Type.Accent}
              onClick={() => setCreateProfessionalHolidayModal(true)}
            />
          ) : null}
        </div>
      ) : null}
      <table className="professional-holidays-table">
        <thead>
          <tr>
            <th>Name</th>
            <th className="center">Org Unit</th>
            <th className="center">Date</th>
            <th className="center">Repeat Annualy</th>
            <th className="center">Text</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {professionalHolidays.map((professionalHoliday) => (
            <tr key={professionalHoliday.id}>
              <td>{professionalHoliday.value.name}</td>
              <td className="center">
                <div className="count-margin-fix">{professionalHoliday.value.orgUnits.length}</div>
              </td>
              <td className="center">
                {professionalHoliday.value.date ? moment(professionalHoliday.value.date).format('DD.MM.YYYY') : ''}
              </td>
              <td className="center">
                {professionalHoliday.value.repeatAnnually ? (
                  <i className="material-icons check-success">done</i>
                ) : (
                  <i className="material-icons">remove</i>
                )}
              </td>
              <td className="center">
                {professionalHoliday.value.text ? (
                  <i className="material-icons check-success">done</i>
                ) : (
                  <i className="material-icons">remove</i>
                )}
              </td>
              <td className="center">
                {hasRole ? (
                  <Popover className="relative">
                    <Popover.Button id="popover-btn" className="vert-menu-btn">
                      <i className="material-icons">more_vert</i>
                    </Popover.Button>

                    <Popover.Panel className="absolute z-10">
                      <i
                        className="material-icons"
                        onClick={() => {
                          editProfessionalHolidayModalVisibility(professionalHoliday)
                        }}
                      >
                        edit
                      </i>
                      <i
                        className="material-icons"
                        onClick={() => {
                          confirmDeleteProfessionalHolidayModalVisibility(professionalHoliday)
                        }}
                      >
                        delete
                      </i>
                      <i
                        className="material-icons"
                        onClick={() =>
                          showProfessionalHoliday({
                            professionalHoliday,
                          })
                        }
                      >
                        {professionalHoliday.value.visible ? 'visibility' : 'visibility_off'}
                      </i>
                    </Popover.Panel>
                  </Popover>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <CreateProfessionalHoliday
        orgUnits={orgUnits}
        opened={createProfessionalHolidayModal}
        onOk={() => {
          getProfessionalHolidays(props.year)
          setCreateProfessionalHolidayModal(false)
        }}
        onClose={() => setCreateProfessionalHolidayModal(false)}
      />
      {professionalHolidayToDelete ? (
        <DeleteProfessionalHoliday
          opened={deleteProfessionalHolidayModal}
          onOk={() => {
            getProfessionalHolidays(props.year)
            setDeleteProfessionalHolidayModal(false)
            deleteProfessionalHoliday(null)
          }}
          onClose={() => {
            setDeleteProfessionalHolidayModal(false)
            deleteProfessionalHoliday(null)
          }}
          professionalHoliday={professionalHolidayToDelete}
        />
      ) : null}
      {professionalHolidayToChange ? (
        <EditProfessionalHoliday
          orgUnits={orgUnits}
          opened={editProfessionalHolidayModal}
          onOk={() => {
            getProfessionalHolidays(props.year)
            setEditProfessionalHolidayModal(false)
            setProfessionalHolidayToChange(null)
          }}
          onClose={() => {
            setEditProfessionalHolidayModal(false)
            setProfessionalHolidayToChange(null)
          }}
          professionalHoliday={professionalHolidayToChange}
        />
      ) : null}
    </div>
  )
}
