import { Box, xcss } from "@atlaskit/primitives";
import {SpanBlock} from 'components/UI/skeleton-table';
import {tableRowConfig} from './constants'

export const TableRow = () => (
  <Box
    xcss={xcss({
      display: "grid",
      gridTemplateColumns: "140px 80px 572px 208px 118px",
    })}
  >
    {tableRowConfig.map((config, index) => (
      <SpanBlock key={index} {...config} />
    ))}
  </Box>
)
