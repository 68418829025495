import Button from '@atlaskit/button/new'
import { token } from '@atlaskit/tokens'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import Lozenge from '@atlaskit/lozenge'
import {
  announcementPriorityColorMap,
  announcementPriorityLabelMap,
  AnnouncementPriorityEnum,
} from 'utils/constants/announcements'
import Modal, { ModalFooter, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { useDeleteAnnouncementMutation } from 'services/api'
import { LocationsList } from '../locations-list'
import { IDeleteAnnouncementModalProps } from './types'
import { DeleteAnnouncementModalBodyStyles, DeleteAnnouncementModalHeaderStyles } from './styles'

export const DeleteAnnouncementModal = (props: IDeleteAnnouncementModalProps) => {
  const { onClose, payload, onDeleted } = props

  if (!payload) return null

  const [deleteAnnouncement, { isLoading }] = useDeleteAnnouncementMutation()

  const { startDate, endDate, text, priority, locations } = payload

  const handelDelete = async () => {
    try {
      await deleteAnnouncement(payload.id)
      onDeleted && onDeleted(payload.id)
      onClose()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ModalTransition>
      <Modal onClose={onClose} width={448}>
        <DeleteAnnouncementModalHeaderStyles>
          <ErrorIcon primaryColor={token('color.icon.danger')} size="large" label="" />
          <ModalTitle>Delete announcement</ModalTitle>
        </DeleteAnnouncementModalHeaderStyles>
        <DeleteAnnouncementModalBodyStyles>
          <div className="dates">
            <span className="label">Display dates:</span>
            <span className="value">
              {getFormattedDate(startDate, 'coreHRDateFormat')} - {getFormattedDate(endDate, 'coreHRDateFormat')}
            </span>
          </div>
          <div className="priority">
            <span className="label">Priority:</span>
            <span className="value">
              <Lozenge appearance={announcementPriorityColorMap[priority as AnnouncementPriorityEnum]}>
                {announcementPriorityLabelMap[priority as AnnouncementPriorityEnum]}
              </Lozenge>
            </span>
          </div>
          <div>
            <span className="label">Announcement: {text}</span>
          </div>
          <div className="locations">
            <span className="label">Location:</span>
            <LocationsList locations={locations} />
          </div>
        </DeleteAnnouncementModalBodyStyles>
        <ModalFooter>
          <Button onClick={onClose} appearance="subtle">
            Cancel
          </Button>
          <Button appearance="danger" onClick={handelDelete} isLoading={isLoading}>
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </ModalTransition>
  )
}
