import styled from "styled-components";
import { token } from "@atlaskit/tokens";

export const AnnouncementsStyles = styled.div`
  width: 1144px;
  padding: ${token("space.300")} 0 0 ${token("space.300")} ;

  .table-header {
    display: flex;
    flex-direction: column;
    gap: ${token("space.200")};
    margin-bottom: ${token("space.300")};

    &-title {
      font-size: 24px;
      font-weight: 500;
    }
  }

  .table-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    // TODO: remove after create component button
    
    & > button {
      display: flex;
      min-height: 32px;
      padding: 0px ${token("space.150")};
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      border: 1px solid ${token("color.border")};
      background: ${token("color.background.accent.blue.subtlest")};

      &:hover {
        background: ${token("color.background.accent.blue.subtlest.hovered")};
      }

      &:active {
        background: ${token("color.background.accent.blue.subtlest.pressed")};
      }

      & > span {
        color: ${token("color.text")};
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        flex-grow: 0;
      }
    }
  }
`
