import Button from '@atlaskit/button/new'
import { useEffect, useMemo } from 'react'
import { AnnouncementPayloadType } from 'interfaces/common/announcements.interface'
import { getFormattedDate } from 'utils/helpers/formatted-date'
import { Controller, useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import Select, { type ValueType as Value } from '@atlaskit/select'
import Modal, { ModalFooter, ModalHeader, ModalTitle, ModalTransition } from '@atlaskit/modal-dialog'
import { ErrorMessage, Label } from '@atlaskit/form'
import { AnnouncementPriorityEnum, announcementPriorityLabelMap } from 'utils/constants/announcements'
import TextArea from '@atlaskit/textarea'
import { DateRange } from '../date-range'
import { useGetLocationsQuery, useUpdateAnnouncementMutation } from 'services/api'
import { IAnnouncementForm, Option } from '../types'
import { announcementFormErrorsMsgMap, announcementPrioritySelectOptions } from '../constants'
import { IEditAnnouncementModalProps } from './types'
import { EditAnnouncementModalBodyStyles } from './styles'
import { FieldCounter } from '../field-counter'

export const EditAnnouncementModal = (props: IEditAnnouncementModalProps) => {
  const { onClose, payload } = props

  const { data: locationsList, isLoading: isLoadingLocations } = useGetLocationsQuery()
  const [updateAnnouncement, { isLoading: isUpdatingAnnouncement }] = useUpdateAnnouncementMutation()

  const methods = useForm<IAnnouncementForm>()

  const { handleSubmit, control, reset } = methods

  const locationSelectOptions: Value<Option[]> = useMemo(() => {
    if (!locationsList) return []

    return locationsList.map(({ name: label, id: value }) => ({
      label,
      value,
    }))
  }, [locationsList])

  const onSubmit: SubmitHandler<IAnnouncementForm> = async (data) => {
    const { startDate, endDate, priority, locations, text } = data

    const body: AnnouncementPayloadType = {
      startDate: getFormattedDate(startDate, 'dateFormatDB'),
      endDate: getFormattedDate(endDate, 'dateFormatDB'),
      priority: priority.value,
      locations: locations.map(({ value }: Option) => value),
      text,
    }

    try {
      if (payload) {
        await updateAnnouncement({ id: payload.id, body })
        onClose()
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    if (locationsList && payload) {
      reset({
        ...payload,
        priority: {
          label: announcementPriorityLabelMap[payload.priority],
          value: payload.priority as AnnouncementPriorityEnum,
        },
        locations: payload.locations.map(({ id, name }) => ({
          label: name,
          value: id,
        })),
      })
    }
  }, [payload, locationsList])

  return (
    <ModalTransition>
      <Modal onClose={onClose} width={448} autoFocus={false}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalHeader>
            <ModalTitle>Edit announcement</ModalTitle>
          </ModalHeader>
          <EditAnnouncementModalBodyStyles>
            <FormProvider {...methods}>
              <DateRange />
              <Controller
                name="priority"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...rest }, fieldState: { error } }) => (
                  <div>
                    <Label htmlFor="priority-select">Priority</Label>
                    <Select
                      {...rest}
                      inputId="priority-select"
                      placeholder="Priority"
                      options={announcementPrioritySelectOptions}
                      isClearable
                      value={value}
                    />
                    {error && <ErrorMessage>{announcementFormErrorsMsgMap.priority.required}</ErrorMessage>}
                  </div>
                )}
              />
              <Controller
                name="locations"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...rest }, fieldState: { error } }) => (
                  <div>
                    <Label htmlFor="locations-select">Location</Label>
                    <Select
                      {...rest}
                      inputId="locations-select"
                      placeholder={isLoadingLocations ? 'Loading locations...' : 'Select location'}
                      isLoading={isLoadingLocations}
                      options={locationSelectOptions}
                      isClearable
                      isMulti
                      value={value}
                    />
                    {error && <ErrorMessage>{announcementFormErrorsMsgMap.locations.required}</ErrorMessage>}
                  </div>
                )}
              />
              <Controller
                name="text"
                control={control}
                rules={{ required: true }}
                render={({ field: { value, ...rest }, fieldState: { error } }) => (
                  <div className="text-area">
                    <>
                      <Label htmlFor="text">Announcement</Label>
                      {/* TODO: Fix TS! */}
                      {/* @ts-ignore */}
                      <TextArea
                        {...rest}
                        id="text"
                        maxHeight="216px"
                        minimumRows={10}
                        placeholder="Enter text"
                        maxLength={500}
                        value={value}
                      />
                      <FieldCounter className="field-counter" value={value ? value.length : 0} maxLength={500} />
                    </>
                    {error && <ErrorMessage>{announcementFormErrorsMsgMap.text.required}</ErrorMessage>}
                  </div>
                )}
              />
            </FormProvider>
          </EditAnnouncementModalBodyStyles>
          <ModalFooter>
            <Button onClick={onClose} appearance="subtle">
              Cancel
            </Button>
            <Button type="submit" appearance="primary" isLoading={isUpdatingAnnouncement}>
              Edit
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </ModalTransition>
  )
}
