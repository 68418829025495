import { AnnouncementPriorityEnum, announcementPriorityLabelMap } from 'utils/constants/announcements'
import { AnnouncementPriorityOptionsType, IAnnouncementForm } from './types'

export const getEnumValues = <T extends object>(enumeration: T): Array<T[keyof T]> => Object.values(enumeration)

export const announcementPrioritySelectOptions: AnnouncementPriorityOptionsType = getEnumValues(AnnouncementPriorityEnum).map(
  (type: AnnouncementPriorityEnum) => ({
    label: announcementPriorityLabelMap[type],
    value: type,
  }),
)

export const announcementFormErrorsMsgMap: {[key in keyof IAnnouncementForm]: {required: string}} = {
  startDate: {
    required: 'Please choose start date',
  },
  endDate: {
    required: 'Please choose end date',
  },
  priority: {
    required: 'Please choose priority',
  },
  locations: {
    required: 'Please select location',
  },
  text: {
    required: 'Please write announcement',
  },
}
