import { Box, xcss } from "@atlaskit/primitives";
import {SerializedStylesType} from 'types'

export const RoundSpanBlock = ({ width, height }: SerializedStylesType) => 
  <Box 
    xcss={xcss({
      borderRadius: "border.radius.circle",
      minHeight: "8px",
      minWidth: "24px",
      backgroundColor: "color.background.accent.gray.subtlest",
      height: height || "auto",
      width: width || "auto",
    })}
  />
