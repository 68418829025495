// TODO: fix eslint errors
/* eslint-disable */
import { Box, xcss } from '@atlaskit/primitives'
import { SerializedStylesType } from 'types'

export const SpanBlock = (props: SerializedStylesType) => (
  <Box
    xcss={xcss({
      borderRadius: 'border.radius.100',
      minHeight: '8px',
      minWidth: '24px',
      backgroundColor: 'color.background.accent.gray.subtlest',
      height: props.height ? props.height : 'auto',
      width: props.width ? props.width : 'auto',
      ...props,
    })}
  />
)
